import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Row, Col } from 'react-bootstrap';
import { GFLWeaponDB } from '../../../modules/exilium/components/exilium-weapon-db';

const ExiliumGuidesBatt: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page gfl-guide'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Battle Pass</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_bp.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Battle Pass</h1>
          <h2>
            Battle Pass in GFL 2 - how it works and what you can get from it.
          </h2>
          <p>
            Last updated: <strong>19/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Battle Pass" />
        <StaticImage
          src="../../../images/exilium/generic/battle.webp"
          alt="Guides"
        />
        <p>
          Similar to most gacha games, GFL 2 has a Battle Pass that offers two
          reward paths: <strong>free for everyone and paid for spenders</strong>
          . The rewards include EXP materials, skill materials, Sardis Gold,
          Pulls, BP only SSR Weapons etc. To progress in the Battle Pass and
          receive the rewards, you must complete a set of daily and weekly
          missions - and the rewards are split into levels.
        </p>
        <p>
          The Battle Pass costs <strong>10$</strong> for the paid path, but
          there's also a second paid option that gives additional rewards and
          instantly unlocks some levels of the Pass (and this option costs{' '}
          <strong>$20</strong>).
        </p>
        <SectionHeader title="SSR Weapons" />
        <p>
          Here's the list of SSR Weapons available to choose from the Battle
          Pass:
        </p>
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <GFLWeaponDB name="Arcana" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Classified Manuscript" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Crowned Jackelope" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Guerno" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Mjölnir" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Papa-Figo" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Expeditionary Pigeon" mode="box" />
          </Col>
        </Row>
        <SectionHeader title="Best Battle Pass weapon to pick?" />
        <p>
          The top priority weapon to aim for first is{' '}
          <GFLWeaponDB name="Guerno" mode="inline" />. The Assault Rifle users
          are the most versatile in the game and the BP weapon is often very
          close to their signature or sometimes even better. In theory, when
          maxed SMG <GFLWeaponDB name="Classified Manuscript" mode="inline" />{' '}
          is great when math is involved but currently no character can make
          full use of it, so it's lower on the priority list (but you can use it
          as stack stick on Suomi if you don't have her signature).
        </p>
        <p>
          Next, the Shotgun -{' '}
          <GFLWeaponDB name="Expeditionary Pigeon" mode="inline" /> is pretty
          good choice, but you need to V3 before it becomes good. The Sniper
          Rifle - <GFLWeaponDB name="Arcana" mode="inline" /> needs to be max
          duped before it can actually be worth using.
        </p>
        <p>So basically, focus on AR first, then move to Shotgun/SMG.</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesBatt;

export const Head: React.FC = () => (
  <Seo
    title="Battle Pass | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Battle Pass in GFL 2 - how it works and what you can get from it."
    game="exilium"
  />
);
